/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import AgencyLoginPage from '../../containers/LoginPage/index'
// import AdminHomePage from '../../containers/AdminHomePage/index'
import PlayersPage from '../../containers/PlayersPage/index'
import PrinterEval from '../../containers/PrinterEval/index'
import AgenciesPage from '../../containers/AgenciesPage/index'
import PlayerCreate from '../../containers/PlayerCreate/index'
import AgencyCreate from '../../containers/AgencyCreate/index'
import PlayersLoader from '../../containers/PlayersLoader/index'
import SimuPlayersLoader from '../../containers/SimuPlayersLoader/index'
import SimuPlayersArchivePage from '../../containers/SimuPlayersArchivePage/index'
import SimuPlayersPage from '../../containers/SimuPlayersPage/index'
import SimuPlayerEdit from '../../containers/SimuPlayerEdit/index'
import SimuPlayerTransfer from '../../containers/SimuPlayerTransfer/index'
import PlayerEdit from '../../containers/PlayerEdit/index'
import PlayerStats from '../../containers/PlayerStats/Index'
import StatsSimu from '../../containers/StatsSimu/index'
import StatsEval from '../../containers/StatsEval/index'
import SimuPlayersWorkshopPage from '../../containers/SimuPlayersWorkshopPage/index'
import Stats from '../../containers/Stats/index'
import AgenciesLoader from '../../containers/AgenciesLoader/index'
import ResetPassword from '../../containers/ResetPassword/index'
// import ChangePassword from '../../containers/ChangePassword/index'
import SimuPlayerCreate from '../../containers/SimuPlayerCreate/index'
// import Downloads from '../../containers/Downloads/index'
// import News from '../../containers/News/index'
import ScaleEvalTGD1 from '../ScaleEval/tgd1'
import ScaleEvalTGD2 from '../ScaleEval/tgd2'
import { Helmet } from 'react-helmet'
// import Agencyprofile from '../AgencyProfile'
import JourneysLoader from '../JourneysLoader/index'
import AgencyEdit from '../../containers/AgencyEdit/index'
import config from '../../config/configurator'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import StatsSimuB2VM from '../StatsSimuB2VM'
import { Redirect } from 'react-router-dom/cjs/react-router-dom'

export const App = () => {
  const loginStatus = useSelector((state) => state.toJS().global.loginStatus)
  const connectedSwitch = () => {
    console.log(config.features.simu)
    return (
      <Router>
        <Switch>
          <Route path='/agencies'><AgenciesPage /></Route>
          {config.features.elearning ? <Route path='/players/edit/stats/:id'><PlayerStats /></Route> : null}
          {config.features.elearning ? <Route path='/players/edit/:id'><PlayerEdit /></Route> : null}
          {config.features.elearning ? <Route path='/players/create'><PlayerCreate /></Route> : null}
          {config.features.elearning ? <Route path='/players/'><PlayersPage /></Route> : null}
          {config.title === 'ECF' || config.title === 'PP ADMIN' ? <Route path='/simuplayers/transfer/:id'><SimuPlayerTransfer /></Route> : null}
          {config.features.simu ? <Route path='/simuplayers/edit/:id'><SimuPlayerEdit /></Route> : null}
          {config.features.simu ? <Route path='/simuplayers/statsb2vm/:id'><StatsSimuB2VM /></Route> : null}
          {config.features.eval ? <Route path='/simuplayers/archive'><SimuPlayersArchivePage /></Route> : null}
          {config.features.simu ? <Route path='/simuplayers/workshop'><SimuPlayersWorkshopPage/></Route> : null}
          {(config.features.simu || config.features.eval) ? <Route path='/simuplayers/stats/:id'><StatsSimu /></Route> : null}
          {config.features.simu ? <Route path='/simuplayers/eval/:id'><StatsEval /></Route> : null}
          {config.features.simu ? <Route path='/simuplayers/create'><SimuPlayerCreate /></Route> : null}
          {config.features.simu ? <Route path='/simuplayers'><SimuPlayersPage /></Route> : null}
          {config.features.simu ? <Route path='/Stats'><Stats/></Route> : null}
          <Route path='/agency/create'><AgencyCreate /></Route>
          <Route path='/agency/edit/:id'><AgencyEdit /></Route>

          {/* <Route path='/AgencyProfile'><Agencyprofile /></Route> */}
          {/* <Route path='/News'><News /></Route> */}
          {/* <Route path='/Downloads'><Downloads /></Route> */}
          <Route path='/scaleeval/tgd1'><ScaleEvalTGD1 /></Route>
          <Route exact path='/scaleeval'><Redirect to='/scaleeval/tgd1' /></Route>
          <Route path='/scaleeval/tgd2'><ScaleEvalTGD2 /></Route>
          {/* <Route path='/password/:id'><ChangePassword /></Route> */}
          {/* <Route path='/admin'><AdminHomePage /></Route> */}
        </Switch>
      </Router>
    )
  }

  const unconnectedSwitch = () => {
    return (
      <Router>
        <Switch>
          <Route path='/printereval/:display/:lastname/:firstname/:email/:license/:totalscore/:hours/:glasses/:score1/:score2/:score3/:score4/:score5/:score6/:score7/:autorisation/:maxscore1/:maxscore2/:maxscore3/:maxscore4/:maxscore5/:maxscore6/:maxscore7'><PrinterEval/></Route>
          <Route path='/printereval/:display/:lastname/:firstname/:email/:license/:totalscore/:hours/:glasses/:score1/:score2/:score3/:score4/:score5/:score6/:score7/:autorisation'><PrinterEval/></Route>
          <Route path='/printereval/:display/:lastname/:firstname/:email/:license/:totalscore/:hours/:glasses/:score1/:score2/:score3/:score4/:score5/:score6/:score7'><PrinterEval/></Route>
          <Route path='/resetPassword/:token/:studentId'><ResetPassword /></Route>
          <Route path='/'><AgencyLoginPage /></Route>
        </Switch>
      </Router>
    )
  }

  return (
    <Router>
      <div>
        <Helmet titleTemplate='Quiz %s' defaultTitle={config.title}>
          <meta name='description' content='administration' />
        </Helmet>
        {loginStatus === 'connected' ? connectedSwitch() : unconnectedSwitch()}
        {config.features.simu ? null : <JourneysLoader />}
        <AgenciesLoader />
        {config.features.simu ? <SimuPlayersLoader /> : null}
        {config.features.elearning ? <PlayersLoader /> : null}
      </div>
    </Router>
  )
}

const withConnect = connect(null)
const arg = compose(withConnect)(App)
// let arg = compose(withSaga, withConnect)(Players)

export default arg
